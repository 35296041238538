import React from 'react';

import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';
import file from './openapi.json'
import logo from './logo.svg'
 const apiDescriptionDocument = file;


function App() {
    return (
        <div className="App">
            <API
                apiDescriptionDocument= {apiDescriptionDocument}
                hideExport={true}
                layout={"responsive"}
                logo={logo}
            />
        </div>
    );
}

export default App;
